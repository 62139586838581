<style lang="scss">
$white: #ffffff;
$black: #000;
$primary: #7459dc;
#front-end-app {
  .form-control {
    height: calc(1.5em + 1.3rem + 15px);
    background-color: $white;
    border: none;
  }
  .page-info {
    font-size: 16px;
    font-weight: 400;
  }
  .container-fluid {
    width: calc(100vw);
  }
  // .btn {
  //   // border-radius: 10px;
  // }
  .sticky-t-details {
    padding-bottom: 10px;
    position: sticky;
    top: 50px;
    z-index: 998;
    background: #eef0f8;
    padding-top: 15px;
  }
}
.bg-light-default {
  background-color: $white;
}
// .vc-pane-container {
//   @media (min-width: 100px) and (max-width: 992px) {
//     width: calc(100vw - 32px);
//   }

//   @media (min-width: 992px) {
//     width: calc(100vw / 2.1);
//   }
// }
.vc-weekday,
.vc-title,
.footer-total-amount {
  color: $primary !important;
}
.vc-container {
  border: none;
  box-shadow: none;
}
.vc-panel {
  background: #fcfcff !important;
}
.text-hover-primary-cust {
  &:hover {
    color: $primary !important;
  }
}
.text-dark-cust {
  color: $black;
}
.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.color-prim {
  color: #8950fc;
}
</style>
<template>
  <div class="d-flex flex-column flex-root" id="front-end-app">
    <!-- begin:: Header Mobile -->
    <!-- <KTHeaderMobile></KTHeaderMobile> -->

    <!-- end:: Header Mobile -->

    <!-- begin::Body -->
    <div class="d-flex flex-row flex-column-fluid page mt-20">
      <div id="kt_wrapper" class="d-flex flex-column flex-row-fluid wrapper">
        <KTHeader v-if="!getErrorsStatus"></KTHeader>
        <div class="d-flex flex-column-fluid">
          <transition name="fade-in-up">
            {{ layoutConfig }}
            <router-view />
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
// import KTHeaderMobile from "@/includes/header/HeaderMobile.vue";
import KTHeader from "@/includes/header/Header.vue";
// import HtmlClass from "@/core/services/htmlclass.service";
export default {
  name: "front-end-app",
  components: {
    // KTHeaderMobile,
    KTHeader
  },
  beforeMount() {
    // HtmlClass.init(this.layoutConfig());
  },
  computed: {
    ...mapGetters(["layoutConfig", "getErrorsStatus"]),

    /**
     * Check if the page loader is enabled
     * @returns {boolean}
     */
    loaderEnabled() {
      return !/false/.test(this.layoutConfig("loader.type"));
    },

    /**
     * Check if container width is fluid
     * @returns {boolean}
     */
    contentFluid() {
      return this.layoutConfig("content.width") === "fluid";
    },

    /**
     * Page loader logo image using require() function
     * @returns {string}
     */

    /**
     * Set the right toolbar display
     * @returns {boolean}
     */
    toolbarDisplay() {
      // return !!this.layoutConfig("toolbar.display");
      return true;
    },

    /**
     * Set the subheader display
     * @returns {boolean}
     */
    subheaderDisplay() {
      return !!this.layoutConfig("subheader.display");
    }
  }
};
</script>
