<style lang="scss">
#kt_header {
  top: 0;
  box-shadow: rgb(0 0 0 / 5%) 0px 6px 24px 0px, rgb(0 0 0 / 8%) 0px 0px 0px 1px;
  background: white;
  position: fixed;
  z-index: 999;
  left: 0;
  // width: 100%;
  align-items: center;
  padding: 10px 0 10px 0;
  // height: 50px;
  .logo-wrapper {
    //  width:20%
    img {
      width: 120px;
    }
  }
}
</style>

<template>
  <!-- begin:: Header -->
  <div
    id="kt_header"
    ref="kt_header"
    class="header"
    v-bind:class="headerClasses"
  >
    <div class="container-fluid d-flex justify-content-between">
      <router-link
        :to="{ name: 'frontend.home', params: { slug: client_slug } }"
        class="no-underline"
        ><div class="logo-wrapper" style="height:50px; width:140px;">
          <img
            style="height:100%; width:auto; object-fit:contain;"
            :src="
              getMobileLocationJobList
                ? getMobileLocationJobList.service_provider.logo
                : getServiceProviderData.logo
            "
            alt="finger scanner logo"
          />
        </div>
      </router-link>

      <router-link
        class="btn btn-sm btn-outline-info"
        v-if="
          getServiceProviderData.support_info &&
            getServiceProviderData.support_info != ''
        "
        :to="{ name: 'frontend.support', params: { slug: client_slug } }"
      >
        Support
      </router-link>
    </div>
  </div>
  <!-- end:: Header -->
</template>

<script>
import { mapGetters } from "vuex";
// import KTTopbar from "@/includes/header/Topbar.vue";
import KTLayoutHeader from "@/assets/js/layout/base/header.js";
import KTLayoutHeaderMenu from "@/assets/js/layout/base/header-menu.js";
import { FETCH_SERVICE_PROVIDER_DATA } from "@/core/services/store/actions.type";

export default {
  data() {
    return {
      client_slug: ""
    };
  },
  name: "KTHeader",
  components: {
    // KTTopbar
  },
  mounted() {
    // Init Desktop & Mobile Headers
    KTLayoutHeader.init("kt_header", "kt_header_mobile");
    this.client_slug = this.$route.params.client_slug;
    // console.log("aibabck");

    if (this.$route.name != "mobile.location.reg") {
      this.$store.dispatch(FETCH_SERVICE_PROVIDER_DATA, {
        client_slug: this.client_slug,
        fetch_slug: "f",
        reset_token: "none"
      });
    }

    // Init Header Menu
    KTLayoutHeaderMenu.init(
      this.$refs["kt_header_menu"],
      this.$refs["kt_header_menu_wrapper"]
    );
  },
  computed: {
    ...mapGetters([
      "layoutConfig",
      "getClasses",
      "getServiceProviderData",
      "getMobileLocationJobList"
    ]),

    /**
     * Check if the header menu is enabled
     * @returns {boolean}
     */
    headerMenuEnabled() {
      return !!this.layoutConfig("header.menu.self.display");
    },

    /**
     * Get extra classes for header based on the options
     * @returns {null|*}
     */
    headerClasses() {
      const classes = this.getClasses("header");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    /**
     * Get extra classes for header menu based on the options
     * @returns {null|*}
     */
    headerMenuClasses() {
      const classes = this.getClasses("header_menu");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    }
  }
};
</script>
